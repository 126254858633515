.input-border-none div[class^="css-"][class$="-menu"] {
    display: none;
}

.input-border-CC div[class^="css-"][class$="-menu"]  {
    display: none;
}

.btn-email{
  min-height: 38px;
  font-weight: 900;
}

.input-border-none div[class^="css-"][class$="-control"]{
  border-color: #d6d6d6!important;
  border-radius: 0 !important;
  max-height: 38px;
  overflow: auto;
  box-shadow: none!important;
}


.input-border-CC div[class^="css-"][class$="-control"]{
  border-color: #d6d6d6!important;
  border-radius: 0 4px 4px 0 !important;
  box-shadow: none!important;
}

.btn-group-email{
  min-width: 60px;
}

.item-email{
  .dropdown-menu{
    left: -34px !important;
    min-width: 80px !important ;
  }
}
.popup{
  background-color: transparent;
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-img{
  height: 40px;
  width: 40px;
}

.popup-header{
  padding-left: 15px;
  font-size: 20px;
  line-height: 45px;
  border-bottom: 1px solid #dddddd;
  border-radius: 5px 5px 0 0;
  font-weight: 500;
}

.popup-btn-close{
  height: 30px;
  width: 30px;
  position: relative;
  top: -7px;
  left: 9px;
}

.popup-bottom{
  display: flex;
  justify-content: space-between;
  width: 90%;
  padding: 10px 0;
  margin-top: auto;
}

.popup-btn-ok{
  color: #333;
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 7px 18px 8px;
  min-width: 100px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  &:focus, &:hover {
    background-color: #ddd;
  }
}

.popup-btn-cancel{
  cursor: pointer;
  border: 1px solid #ddd;
  padding: 7px 18px 8px;
  min-width: 100px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  display: flex;
  &:focus, &:hover {
    background-color: #ddd;
  }
}

.txt-popup-yes-no{
  width: 90%;
  padding-top: 10px;
}

.dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 44px !important;
}

.popup-size-40{
  .modal-dialog{
    width: 40% !important;
  }
}

.popup-size-70{
  .modal-dialog{
    width: 70% !important;
  }
}

.popup-size-80{
  .modal-dialog{
    width: 80% !important;
  }
}

.popup-size-90 {
    .modal-dialog{
    width: 90% !important;
    }
}

div.report-list-grid {
    .dx-datagrid-headers {
        .dx-datagrid-content {
            tr.dx-datagrid-filter-row {
                td.dx-command-select {
                    background-color: #f7f7f7 !important;
                }
            }
        }
    }
    .dx-datagrid-rowsview {
        .dx-datagrid-content {
            tr.dx-selection {
                td {
                    background-color: #f7f7f7 !important;
                }
            }
        }
    }
}
